<template>
  <div class="page_wrap login">
    <!-- <div class="nav_wrap container">
      <div class="left">
        <div class="logo">
          <img src="../assets/login/logo.png" alt="" />
        </div>
        <div>
          <router-link to="/index">首页</router-link>
          <router-link to="/login">选课</router-link>
        </div>
      </div>
      <div class="right">
        <div class="btn_login">登录/注册</div>
      </div>
    </div> -->
    <div class="bg_wrap" v-show="!show_forget">
      <div
        :class="show_qr == false ? 'triangle_' : 'triangle_ show'"
        @click="change_wx"
      ></div>
      <div class="login_wrap" v-show="show_qr == false">
        <div class="tab_wrap_">
          <div
            :class="tab_index == index ? 'active item_tab' : 'item_tab'"
            @click="change_tab(index)"
            v-for="(item, index) in tabname"
            :key="index"
          >
            {{ item.name }}
          </div>
          <div class="center">|</div>
        </div>
        <div class="login_input">
          <!-- 短信登录 -->
          <div class="input_item" v-show="tab_index == 0">
            <el-form
              :model="verification"
              :rules="verification_rules"
              ref="verification_ruleForm"
            >
              <el-form-item label="" prop="mobile">
                <el-input
                  placeholder="请输入手机号"
                  v-model="verification.mobile"
                  maxlength="11"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-s-custom"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item
                class="verification_code"
                label=""
                prop="verification_code"
              >
                <el-input
                  v-model="verification.verification_code"
                  placeholder="请输入图形码"
                  autocomplete="off"
                  clearable
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-chat-dot-square"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
                <div class="get_code" @click="refreshCode()">
                  <sIdentify :identifyCode="identifyCode" />
                </div>
              </el-form-item>
              <el-form-item label="" prop="code">
                <el-input
                  placeholder="请输入短验证码"
                  v-model="verification.code"
                  maxlength="6"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-chat-dot-square"
                    style="color: #c7dffe"
                  ></i>
                  <el-button
                    class="get_code"
                    slot="suffix"
                    type="text"
                    @click="get_code(1)"
                    >{{
                      !codeTime ? "获取验证码" : codeTime + "s" + "后获取"
                    }}</el-button
                  >
                </el-input>
              </el-form-item>
            </el-form>
            <!-- <div class="phone_dec" v-if="voice_show">
              <div class="out_phone"></div>
              <div class="Forget_password">接收语音验证码</div>
            </div> -->
            <div class="login_btn">
              <el-button
                type="primary"
                round
                :loading="submitLoading"
                @click="toVerificationLogin('verification_ruleForm')"
                >登录</el-button
              >
            </div>
            <div class="text">
              <el-checkbox v-model="agree_value"></el-checkbox> 我已阅读并同意
              <router-link to="/agreement/0">用户协议</router-link> 和
              <router-link to="/agreement/1">隐私政策</router-link>
            </div>
          </div>
          <!-- 密码登录 -->
          <div class="input_item" v-show="tab_index == 1">
            <el-form :model="form" :rules="rules" ref="ruleForm">
              <el-form-item label="" prop="mobile">
                <el-input
                  placeholder="请输入手机号"
                  v-model="form.mobile"
                  maxlength="11"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-s-custom"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  placeholder="请输入密码"
                  v-model="form.password"
                  show-password
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-unlock"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="phone_dec">
              <div class="out_phone"></div>
              <div class="Forget_password" @click="show_forget = true">
                忘记密码?
              </div>
            </div>
            <div class="login_btn">
              <el-button
                type="primary"
                round
                :loading="submitLoading"
                @click="submit('ruleForm')"
                >登录</el-button
              >
            </div>
            <div class="text">
              <el-checkbox v-model="agree_value"></el-checkbox> 我已阅读并同意
              <router-link to="/agreement/0">用户协议</router-link> 和
              <router-link to="/agreement/1">隐私政策</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="qr_code_wrap" v-show="show_qr == true">
        <div class="text">扫码登录</div>
        <div class="qr_img">
          <div class="code_b" ref="qrCodeUrl" id="qrCodeUrl"></div>
          <div v-if="code_status == 1" class="qr_mark code_success">
            <i class="el-icon-check"></i>
            扫码成功
          </div>
          <div v-if="code_status == 2" class="qr_mark code_success">
            <i class="el-icon-check"></i>
            登录成功
          </div>
          <div
            v-if="code_status == 3"
            class="qr_mark refresh"
            @click="handleRefresh"
          >
            <i class="el-icon-refresh-right"></i>
            已超时
          </div>
        </div>
        <div class="last">
          打开<span>社科赛斯考研APP</span><br />
          扫码登录
        </div>
      </div>
    </div>
    <!-- 重置密码 -->
    <div class="bg_wrap" v-show="show_forget">
      <div class="login_wrap" v-show="show_qr == false">
        <div class="tab_wrap_">
          <div>重置密码</div>
        </div>
        <div class="login_input">
          <div class="input_item">
            <el-form
              :model="forget_form"
              :rules="forget_rules"
              ref="forget_form"
            >
              <el-form-item label="" prop="mobile">
                <el-input
                  placeholder="请输入手机号"
                  v-model="forget_form.mobile"
                  maxlength="11"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-s-custom"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="code">
                <el-input
                  placeholder="请输入短验证码"
                  v-model="forget_form.code"
                  maxlength="6"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-chat-dot-square"
                    style="color: #c7dffe"
                  ></i>
                  <el-button
                    class="get_code"
                    slot="suffix"
                    type="text"
                    @click="get_code(2)"
                    >{{
                      !codeTime ? "获取验证码" : codeTime + "s" + "后获取"
                    }}</el-button
                  >
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password">
                <el-input
                  placeholder="请输入密码"
                  v-model="forget_form.password"
                  show-password
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-unlock"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="password2">
                <el-input
                  placeholder="请确认密码"
                  v-model="forget_form.password2"
                  show-password
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-unlock"
                    style="color: #c7dffe"
                  ></i>
                </el-input>
              </el-form-item>
            </el-form>
            <div class="phone_dec">
              <div class="out_phone"></div>
              <div class="Forget_password" @click="show_forget = false">
                密码登录
              </div>
            </div>
            <div class="login_btn">
              <el-button
                type="primary"
                round
                :loading="submitLoading"
                @click="forget_submit('forget_form')"
                >登录</el-button
              >
            </div>
            <div class="text">
              <el-checkbox v-model="agree_value"></el-checkbox> 我已阅读并同意
              <router-link to="/agreement/0">用户协议</router-link> 和
              <router-link to="/agreement/1">隐私政策</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/config/api";
import { cookieGO, getCookie } from "@/config/cookie";
import { mapActions } from "vuex";
import QRCode from "qrcodejs2";
var timer = null;
import sIdentify from "@/components/sIdentify.vue";
export default {
  components: {
    sIdentify,
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写手机号"));
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };
    var checkPass = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写密码"));
      } else {
        callback();
      }
    };
    var checkPass2 = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.forget_form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var checkCode = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写验证码"));
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      var value = value.replace(/\s+/g, "");
      if (!value) {
        return callback(new Error("请填写手机号"));
      }
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(new Error("手机号格式不正确"));
      } else {
        callback();
      }
    };

    return {
      form: {
        mobile: "",
        password: "",
      },
      rules: {
        password: [{ validator: checkPass, trigger: ["change", "blur"] }],
        mobile: [{ validator: checkPhone, trigger: "blur" }],
      },
      agree_value: true,
      tabname: [
        { id: 2, name: "短信登录" },
        { id: 1, name: "密码登录" },
      ],
      tab_index: 0,
      codeTime: "",
      show_qr: false,
      verification: {
        mobile: "",
        verification_code: "",
        code: "",
      },
      verification_rules: {
        mobile: [{ validator: checkPhone, trigger: "blur" }],
        verification_code: [
          { required: true, message: "请输入图形码", trigger: "blur" },
        ],
        code: [{ validator: checkCode, trigger: "blur" }],
      },
      send_sms_type: "",
      code_status: "", // 1 扫码成功  2 登录成功 3 已超时
      show_forget: false,
      forget_form: {
        mobile: "",
        code: "",
        password: "",
        password2: "",
      },
      forget_rules: {
        mobile: [{ validator: checkPhone, trigger: "blur", required: true }],
        code: [{ validator: checkCode, trigger: "blur", required: true }],
        password: [{ validator: checkPass, trigger: "blur", required: true }],
        password2: [{ validator: checkPass2, trigger: "blur", required: true }],
      },
      identifyCode: "",
      identifyCodes: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
      ], //根据实际需求加入自己想要的字符

      res_source: 2,
      source_url_h5: "",
      voice_show: false, // 语音验证是否显示
      voice_click_flag: true,

      submitLoading: false,
    };
  },
  created() {},
  mounted() {
    if (this.$store.state.logined) {
      this.$router.push({
        path: "/",
      });
    }
    // 登录后返回
    this.re_url = this.$route.query.re_url;

    this.refreshCode();
    this.source_url_h5 = location.href.replaceAll("%2F", "/");

    //绑定事件
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    ...mapActions(["setUserAndState"]),
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.toLogin(2);
        } else {
          return false;
        }
      });
    },
    toVerificationLogin(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.toLogin(1);
        } else {
          return false;
        }
      });
    },
    // 忘记密码
    forget_submit(formName) {
      if (!this.agree_value) {
        this.$message({
          message: "请先阅读并同意用户协议与隐私政策",
          type: "warning",
        });
        return;
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.to_reset_pwd();
        } else {
          setTimeout(() => {
            this.$refs[formName].clearValidate();
          }, 2000);
          return false;
        }
      });
    },
    async to_reset_pwd() {
      try {
        let params = {
          mobile: this.forget_form.mobile.replace(/\s+/g, ""),
          code: this.forget_form.code,
          enter_pwd: this.forget_form.password,
          new_pwd: this.forget_form.password2,
          res_source: 2,
          source_url_h5: this.source_url_h5,
        };
        this.submitLoading = true;
        let res = await api.reset_pwd(params);
        if (res.code == 0) {
          cookieGO("jst", res.result.c);
          cookieGO("logined", JSON.stringify(true));
          cookieGO("userInfo", JSON.stringify(res.result.info));
          this.setUserAndState(res.result.info);
          // this.loginIm(res.result.info.chat_only_code, res.result.info.chat_sign);
          // this.$bus.$emit("loginIm", {
          //   userID: res.result.info.chat_only_code,
          //   userSig: res.result.info.chat_sign
          // });
          this.$message({
            message: "密码修改成功",
            type: "success",
            duration: 1000,
            onClose: () => {
              if (this.re_url) {
                if (
                  this.re_url.indexOf("http://") != -1 ||
                  this.re_url.indexOf("https://") != -1
                ) {
                  location.href = this.re_url;
                } else {
                  this.$router.push({
                    path: this.re_url,
                  });
                }
              } else {
                this.$router.push({
                  path: `/`,
                });
              }
              this.submitLoading = false;
            },
          });
          this.forget_form = {
            mobile: "",
            code: "",
            password: "",
            password2: "",
          };
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          this.submitLoading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },

    async toLogin(type) {
      if (!this.agree_value) {
        this.$message({
          message: "请先阅读并同意用户协议与隐私政策",
          type: "warning",
        });
        return;
      }

      let params = {};
      if (type == 2) {
        params = this.form;
      } else if (type == 1) {
        params = this.verification;
        if (!this.verification.verification_code) {
          this.$message({
            message: "请输入图形码",
            type: "warning",
          });
          this.refreshCode();
          return;
        }

        if (this.verification.verification_code != this.identifyCode) {
          this.$message({
            message: "图形码输入有误",
            type: "warning",
          });
          this.refreshCode();
          return;
        }
      }
      params.type = type;
      params.mobile = params.mobile.replace(/\s+/g, "");
      params.res_source = 2;
      params.source_url_h5 = this.source_url_h5;
      this.submitLoading = true;
      try {
        const res = await api.login(params);
        if (res.code == 0) {
          cookieGO("jst", res.result.c);
          cookieGO("logined", JSON.stringify(true));
          cookieGO("userInfo", JSON.stringify(res.result.info));
          this.setUserAndState(res.result.info);
          // this.loginIm(res.result.info.chat_only_code, res.result.info.chat_sign);
          // this.$bus.$emit("loginIm", {
          //   userID: res.result.info.chat_only_code,
          //   userSig: res.result.info.chat_sign
          // });
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1000,
            onClose: () => {
              if (this.re_url) {
                if (
                  this.re_url.indexOf("http://") != -1 ||
                  this.re_url.indexOf("https://") != -1
                ) {
                  location.href = this.re_url;
                } else {
                  this.$router.push({
                    path: this.re_url,
                  });
                }
              } else {
                this.$router.push({
                  path: `/`,
                });
              }
              this.submitLoading = false;
            },
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
          this.refreshCode();
          this.submitLoading = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    change_tab(index) {
      this.tab_index = index;
    },
    // 触发验证码
    get_code(type) {
      if (type == 1) {
        this.voice_show = true;
        this.voice_click_flag = false;
        setTimeout(() => {
          this.voice_click_flag = true;
        }, 5000);
      }

      this.send_sms_type = type;
      var m = "";
      if (type == 1) {
        m = this.verification.mobile;
      } else if (type == 2) {
        m = this.forget_form.mobile;
      }
      m = m.replace(/\s+/g, "");

      if (!m) {
        this.$message({
          message: "请填写手机号",
          type: "warning",
        });
        return;
      }
      if (!/^(1[0-9])\d{9}$/.test(m)) {
        this.$message({
          message: "手机号码有误，请重填",
          type: "warning",
        });
        return;
      }
      if (type == 1) {
        if (!this.verification.verification_code) {
          this.$message({
            message: "请输入图形码",
            type: "warning",
          });
          this.refreshCode();
          return;
        }

        if (this.verification.verification_code != this.identifyCode) {
          this.$message({
            message: "图形码输入有误",
            type: "warning",
          });
          this.refreshCode();
          return;
        }
      }
      this.code_last();
    },
    // 倒计时
    code_last() {
      if (this.codeTime > 0) {
        this.$message({
          showClose: true,
          message: "请在" + this.codeTime + "秒后获取",
          type: "warning",
        });
        return;
      } else {
        this.tosend_sms();
        this.codeTime = 60;
        let timer = setInterval(() => {
          this.codeTime--;
          if (this.codeTime < 1) {
            clearInterval(timer);
            this.codeTime = 0;
          }
        }, 1000);
      }
    },
    async tosend_sms() {
      try {
        let params = {};
        params.type = this.send_sms_type;
        if (this.send_sms_type == 1) {
          params.mobile = this.verification.mobile;
        } else if (this.send_sms_type == 2) {
          params.mobile = this.forget_form.mobile;
        }
        params.mobile = params.mobile.replace(/\s+/g, "");

        let res = await api.send_sms(params);
        if (res.code == 0) {
          this.$message({
            message: "验证码已发送",
            type: "success",
          });
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    change_wx() {
      this.show_qr = !this.show_qr;
      document.getElementById("qrCodeUrl").innerHTML = "";
      this.creatQrCode();
    },
    handleRefresh() {
      this.code_status = "";
      document.getElementById("qrCodeUrl").innerHTML = "";
      this.creatQrCode();
    },
    async creatQrCode() {
      try {
        const res = await api.login_code();
        if (res.code == 0) {
          let { expire, url } = res.result;
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url, // 需要转换为二维码的内容
            width: 146,
            height: 146,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
          let only_code = url.split("only_code=")[1];
          cookieGO("code_expire", only_code, expire);

          var time = expire;
          timer = setInterval(async () => {
            time = time - 2;
            if (!getCookie("code_expire")) {
              clearInterval(timer);
              timer = null;

              // 1 扫码成功  2 登录成功 3 已超时
              this.code_status = 3;
            }

            const res_scan = await api.polling({
              only_code: only_code,
            });
            if (res_scan.result == 2) {
              // 1 扫码成功  2 登录成功 3 已超时
              this.code_status = 1;
            }
            if (res_scan.result == 4) {
              // 取消登录
              // 1 扫码成功  2 登录成功 3 已超时
              this.code_status = 1;
            } else if (
              res_scan.result &&
              res_scan.result.info &&
              res_scan.result.info.id
            ) {
              clearInterval(timer);
              timer = null;
              // 1 扫码成功  2 登录成功 3 已超时
              this.code_status = 2;
              cookieGO("jst", res_scan.result.c);
              cookieGO("logined", JSON.stringify(true));
              cookieGO("userInfo", JSON.stringify(res_scan.result.info));
              this.setUserAndState(res_scan.result.info);
              this.$message({
                message: "登录成功",
                type: "success",
                duration: 1000,
                onClose: () => {
                  this.$router.push({
                    path: `/`,
                  });
                },
              });
            }
          }, 2000);
        } else {
          this.$message({
            message: res.message,
            type: "warning",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 生成随机数
    randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 更新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
      // console.log("当前验证码:", this.identifyCode);
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      // console.log("data, len:", data, len);
      for (let i = 0; i < len; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length - 1)];
      }
    },
    loginIm(userID, userSig) {
      let promise = this.tim.login({
        userID: userID,
        userSig: userSig,
      });
      promise
        .then(function (imResponse) {
          // console.log(imResponse.data);
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。v2.5.1 起支持
            // console.log(imResponse.data.errorInfo);
          }
        })
        .catch(function (imError) {
          console.warn("login error:", imError); // 登录失败的相关信息
        });
    },
    keyDown(e) {
      if (!this.submitLoading) {
        if (e.keyCode == 13) {
          if (this.show_forget) {
            this.forget_submit("forget_form");
          } else {
            if (this.show_qr) {
            } else {
              if (this.tab_index == 0) {
                this.toVerificationLogin("verification_ruleForm");
              } else if (this.tab_index == 1) {
                this.submit("ruleForm");
              }
            }
          }
        }
      }
    },
  },
  beforeDestroy() {
    clearInterval(timer);
    timer = null;
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>
<style>
#app {
  min-height: auto;
}
.login .el-input.el-input--prefix {
  width: 276px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  display: block;
  /* margin-bottom: 14px; */
}
.login .el-input.el-input--suffix {
  width: 276px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  display: block;
  /* margin-bottom: 14px; */
  line-height: 48px;
}
.login .el-input__inner {
  width: 276px;
  height: 48px;

  border: 1px solid #e8e8e8;
  border-radius: 4px;
}
.login_btn .el-button {
  width: 278px;
  height: 47px;
  border-radius: 24px;
  margin-top: 15px;
}
.get_code.el-button {
  margin-right: 10px;
}
.login .el-checkbox__inner {
  z-index: 0;
}
</style>
<style lang="less" scoped>
.page_wrap {
  .nav_wrap {
    margin: 0 auto;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 239px;
      height: 29px;
      // background-color: #ff6700;
    }
    .left {
      display: flex;
      align-items: center;
      .logo {
        margin-right: 37px;
      }
      a {
        margin-right: 30px;
        font-size: 16px;
        font-weight: 500;
        color: #1f1f1f;
        line-height: 28px;
      }
    }
  }
  .bg_wrap {
    background: url(../assets/login/login_bg2.png) no-repeat center;
    height: 620px;
    min-width: 1200px;
    position: relative;
    .login_wrap {
      // width: 350px;
      // height: 380px;
      background: #ffffff;
      border-radius: 4px;
      position: absolute;
      right: 50%;
      left: 50;
      margin-right: -600px;
      margin-top: 90px;
      // padding: 45px 35px;
      padding: 25px 35px;
      .tab_wrap_ {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        position: relative;
        .center {
          position: absolute;
          left: 75px;
          top: 6px;
        }
        .item_tab {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
          margin-right: 14px;
          position: relative;
          cursor: pointer;
        }
        .item_tab:nth-of-type(2) {
          margin-left: 14px;
        }
        .item_tab.active:after {
          position: relative;
          content: "";
          background-color: #479dff;
          width: 44px;
          height: 4px;
          display: block;
          border-radius: 2px;
          left: 11px;
          bottom: -5px;
        }

        color: #333333;
      }
      .phone_dec {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .out_phone {
          font-size: 14px;
          font-weight: 400;
          color: #1e75d8;
          line-height: 28px;
        }
        .Forget_password {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
          cursor: pointer;
          line-height: 28px;
        }
      }
      .text {
        margin-top: 14px;
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        a {
          color: #1e75d8;
        }
      }
    }
    .qr_code_wrap {
      width: 348px;
      height: 403px;
      background: #ffffff;
      border-radius: 4px;
      position: absolute;
      right: 50%;
      left: 50;
      margin-right: -600px;
      margin-top: 90px;
      padding-top: 47px;
      box-sizing: border-box;
      .text {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 28px;
        margin-bottom: 40px;
      }

      .qr_img {
        width: 146px;
        height: 146px;
        margin: 0 auto;
        margin-bottom: 37px;
        position: relative;
        // img {
        //   width: 100%;
        //   height: 100%;
        //   background-color: #ff6700;
        // }
        .qr_mark {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.9);
          left: 0;
          top: 0;
          font-size: 26px;
          line-height: 146px;
        }
        .refresh {
          color: #666;
        }
        .code_success {
          color: #1e75d8;
        }
      }
      .last {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        span {
          color: #1e75d8;
        }
      }
    }
    .triangle_ {
      background: url(../assets/login/change_img.png) no-repeat;
      background-size: contain;
      width: 50px;
      height: 50px;
      position: absolute;
      right: 50%;
      left: 50;
      margin-right: -600px;
      margin-top: 90px;
      z-index: 11111;
      cursor: pointer;
    }
    .triangle_.show {
      background: url(../assets/login/change_phone.png) no-repeat;
      background-size: contain;
    }
  }
}
.verification_code {
  position: relative;
  .get_code {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
</style>
